/* Team Section */
#team {
    padding: 100px 0;
    background: #ffffff;
    overflow: hidden;
}
#team h2 {
    position: relative;
    text-align: left;
}
.team__button {
    text-align: left;
}
.team__contact {
    margin: 20px auto 0 0;
    font-family: "Raleway", sans-serif;
    align-items: center;
    background-color: transparent;
    border-radius: 100vmax;
    display: inline-flex;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 0;
    width: 170px;
    height: 50px;
    position: relative;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    transition: box-shadow .5s ease-in-out;
    outline: none
}
#team .line {
    position: relative;
    margin: 0;
}
#team .team-img {
    width: 240px;
}
#team .thumbnail {
    background: transparent;
    border: 0;
    margin-top: -3px;
}
#team .thumbnail .caption {
    padding: 10px 0 0;
}
#team .caption h3 {
    margin-top: 10px;
}
#team .team__list {
    margin: 20px 0 0;
    padding: 0;
}
#team .team__desc {
    position: relative;
    color: var(--color-dark);
    font-weight: 400;
    margin: 20px 0 0;
    list-style: none;
    padding: 0;
    text-align: left;
}
#team li {
    position: relative;
    color: var(--color-dark);
    font-size: 22px;
    font-weight: 400;
    margin: 0 0 8px 0;
    list-style: none;
    padding: 0;
    text-align: left;
}
#team li span {
    display: inline-block;
    margin-left: 30px;
}
#team li i {
    position: absolute;
    color: var(--primary-dark-color) !important;
    font-size: 22px;
    font-weight: 300;
    top: 5px;
}
@media screen and (max-width: 970px) {
    #team h2 {
        font-size: 50px;
    }
}
@media screen and (max-width: 750px) {
    #team h2 {
        font-size: 34px;
    }
}
@media screen and (max-width: 480px) {
    #team .team__desc {
        font-size: 18px;
    }
}
