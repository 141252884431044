.btn-custom {
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;
    color: var(--color-light);
    background-color: var(--primary-color);
    background-image: linear-gradient(to right, var(--primary-color) 0%, var(--primary-dark-color) 100%);
    padding: 18px 35px 16px 30px;
    margin: 0 0 0 10px;
    width: 258px;
    border-radius: 25px;
    border: 0;
    letter-spacing: 1px;
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.5s linear;
    box-shadow: 0 0 3px 0 #ffffff;
}
.btn-custom span {
    padding-left: 20px;
}
.btn-custom i {
    animation-iteration-count: 2;
    animation: shake 3s cubic-bezier(.36, .07, .19, .97) infinite;
    transform: translate3d(0, 0, 0);
    margin-top: 1px;
}
@keyframes shake {
    70% {
        transform: translate3d(-1px, 0, 0);
    }
    73%, 97% {
        transform: translate3d(-1px, 0, 0);
    }
    76%, 94% {
        transform: translate3d(1px, 0, 0);
    }
    79%, 85%, 91% {
        transform: translate3d(-2px, 0, 0);
    }
    82%, 88% {
        transform: translate3d(2px, 0, 0);
    }
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
    color: #fff;
    background-image: none;
    background-color: var(--primary-dark-color);
}