#compares {
    background: #f6f6f6;
    padding: 100px 0;
    overflow: hidden;
}
.compares {
    position: relative;
    height: auto;
    margin: 0 auto;
    padding: 0;
}
.compares h2 {
    text-align: center;
}
.compares .sub-title {
    line-height: 1.2;
    text-align: center;
    text-transform: none;
    margin: 20px 0 43px;
    color: var(--color-dark);
}
.compares .list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 27.5px;
    /*margin: 120px 0 0 0;*/
}
.compares .item {
    width: 13em;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0 0.75em;
}
.compare {
    position: relative;
    width: 12em;
    height: 12em;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-radius: 1em;
    -webkit-box-shadow: 0.3em 0.3em 0.8em 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0.3em 0.3em 0.8em 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0.3em 0.3em 0.8em 0 rgba(0, 0, 0, 0.2);
    background-color: var(--color-light);
    padding: 2.9em 0 1.9em;
    margin: 4.5em 0 0 0;
}
.compare__icon {
    background-color: #f6f6f6;
    border-radius: 50%;
    width: 5.7em;
    height: 5.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2.8em;
    -webkit-box-shadow: 0.3em 0.3em 0.4em 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0.3em 0.3em 0.4em 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0.3em 0.3em 0.4em 0 rgba(0, 0, 0, 0.2);
}
.item--1 .compare__icon {
    background: var(--color-sheme-1);
}
.item--2 .compare__icon {
    background: var(--color-sheme-2);
}
.item--3 .compare__icon {
    background: var(--color-sheme-3);
}
.compare__icon-inner {
    background-color: #ffffff;
    border-radius: 50%;
    width: 4.5em;
    height: 4.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0.3em 0.3em 0.4em 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0.3em 0.3em 0.4em 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0.3em 0.3em 0.4em 0 rgba(0, 0, 0, 0.2);
}
.compare__svg {
    width: 1.6em;
    height: auto;
}
.compare__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    max-width: 11em;
}
.compare__desc {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
}
.compare__desc h3 {
    text-align: center;
}
.compare__desc h3 span {
    font-size: 1.157em;
}
.compare__desc p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    margin: 0.3em 0;
    line-height: 1.15;
    color: #000000;
    font-size: 0.8em;
}
.compare__desc p span {
    font-size: 0.9091em;
}
.compare__desc p span.margin {
    margin: 0 2.5em 0 0;
}
.compares p .bold {
    font-weight: 700;
}
.compares__source {
    font-size: 20px;
    font-weight: 400;
    text-align: right;
    color: #CBCBCB;
    margin: 60px 0 0 0;
}
@media screen and (max-width: 1200px) {
    .compares .list {
        font-size: 22px;
    }
}
@media screen and (max-width: 1024px) {
    .compares .list {
        font-size: 18px;
    }
}
@media screen and (max-width: 768px) {
    .compares .list {
        font-size: 14px;
    }
    .compares .item {
        margin: 0 0.25em;
    }
    .compare__desc h3 span {
        font-size: 0.8em;
    }
    .compare__desc p span {
        font-size: 1em;
    }
}
@media screen and (max-width: 480px) {
    .compares .list {
        font-size: 20px;
        flex-direction: column;
        /*justify-content: flex-start;*/
        align-items: center;
    }
    .compare__desc h3 span {
        font-size: 1.1em;
    }
    .compare__desc p span {
        font-size: 1em;
    }
}