#video {
    padding: 100px 0;
    overflow: hidden;
    background: #ffffff;
}
#video p {
    line-height: 24px;
    margin: 20px auto 30px;
    max-width: 800px;
    padding: 0 50px;
    color: var(--color-dark);
}
#video .video {
    max-width: 820px;
    margin: 29px auto 0;
    padding: 0;
}
#video .video video {
    width: 100%;
    height: auto;
}
