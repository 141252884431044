/* About Section */
#about {
    padding: 0;
    overflow: hidden;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 550px;
}
#about .about__img,
#about .about__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
}
#about .about__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 0 20px;
}
#about .about-text {
    max-width: 480px;
    margin: 0 auto 0 90px;
}
#about .picture {
    width: 50vw;
    margin: 0;
    background: #fff;
    border-right: 0;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#about img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#about h3 {
    font-size: 22px;
    margin: 0 0 20px;
    text-transform: capitalize;
}
#about h2 {
    position: relative;;
}
#about .line {
    margin: 0 auto 0 0;
}
#about .paragraph {
    margin: 20px 0;
}
#about .paragraph p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    max-width: 500px;
    color: var(--color-dark);
}
#about .list {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}
#about .list__column {
    margin: 0 0 0 7px;
}
#about .about-text li {
    margin-bottom: 8px;
    position: relative;
}
#about li i {
    position: absolute;
    color: var(--primary-dark-color) !important;
    font-size: 18px;
    font-weight: 300;
    top: 6px;
}
#about li p {
    display: inline-block;
    margin: 0 0 0 25px;
    font-weight: 400;
    font-size: 20px;
    color: var(--color-dark);
}
@media screen and (max-width: 1200px) {
    #about .about-text {
        margin: 0 auto 0 30px;
    }
}
@media screen and (max-width: 1024px) {
    #about .about__image {
        display: none;
    }
    #about .about__content {
        margin: 0 auto;
        flex-basis: 100%;
        padding: 100px 20px;
    }
    #about .about-text {
        max-width: none;
        margin: 0 auto;
    }
}
@media screen and (max-width: 480px) {}