.modal-video-button {
    position: relative;
    cursor: pointer;
    width: 130px;
    height: 127px;
    border-radius: 50%;
    background: rgba(45, 42, 115, 0.78);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.modal-video-play {
    margin: 0 -9px 0 0;
}