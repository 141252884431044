#algorithm {
    background-color: #f6f6f6;
    padding: 100px 0;
    overflow: hidden;
}
#algorithm .title {
    text-align: center;
}
#algorithm .sub-title {
    margin: 20px auto 30px;
    max-width: 990px;
    padding: 0 25px 0 50px;
    color: var(--color-dark);
}
.algorithm {
    position: relative;
    font-size: 24px;
    width: 46.75em;
    height: 23em;
    padding: 5em 0 0 0;
    margin: -2em auto 0;
}
.algorithm__bg {
    position: absolute;
    width: 100%;
    height: 100%;
}
.algorithm__icon {
    background-color: #f6f6f6;
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    -webkit-box-shadow: 5px -5px 15px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 5px -5px 15px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 5px -5px 15px 0px rgba(0, 0, 0, 0.3);
}
.algorithm__icon.algorithm__icon--1 {
    top: 5.86em;
    left: 2.95em;
}
.algorithm__icon.algorithm__icon--2 {
    top: 16.58em;
    left: 12.28em;
}
.algorithm__icon.algorithm__icon--3 {
    top: 5.86em;
    left: 21.65em;
}
.algorithm__icon.algorithm__icon--4 {
    top: 16.54em;
    left: 30.495em;
}
.algorithm__icon.algorithm__icon--5 {
    top: 5.86em;
    left: 40em;
}
.algorithm__svg {
    width: 1.5em;
    height: auto;
}
.algorithm__step {
    width: 7.3em;
    height: 5em;
    position: absolute;
}
.algorithm__step.algorithm__step--1 {
    top: 17em;
    left: 1.05em;
    width: 7.2em;
}
.algorithm__step.algorithm__step--2 {
    top: 6.25em;
    left: 8.25em;
    width: 11.5em;
}
.algorithm__step.algorithm__step--3 {
    top: 17em;
    left: 19.8em;
}
.algorithm__step.algorithm__step--4 {
    top: 6.25em;
    left: 28.8em;
    width: 6.5em;
}
.algorithm__step.algorithm__step--5 {
    top: 17em;
    left: 38.65em;
    width: 6.3em;
}
.algorithm__name {
    font-size: 1.5em;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    text-align: left;
    color: var(--color-light);
    position: absolute;
    text-transform: uppercase;
    width: 4em;
    top: 0;
}
.algorithm__name span {
    font-size: 0.374em;
}
.algorithm__name.algorithm__name--1.algorithm__name--screen {
    top: -3.25em;
    left: 1.4em;
}
.algorithm__name.algorithm__name--2.algorithm__name--screen {
    top: 3.9em;
    left: 2.5em;
}
.algorithm__name.algorithm__name--3.algorithm__name--screen {
    top: -3.25em;
    left: 0.65em;
}
.algorithm__name.algorithm__name--4.algorithm__name--screen {
    top: 3.9em;
    left: 0.35em;
}
.algorithm__name.algorithm__name--5.algorithm__name--screen {
    top: -3.25em;
    left: 1.3em;
}
.algorithm__desc {
    font-size: 0.7em;
    line-height: 1.35;
    margin: 0.3em 0 0 0;
    color: #000000;
    text-align: center;
}
@media screen and (max-width: 1200px) {
    .algorithm {
        font-size: 19px;
    }
}
@media screen and (max-width: 1024px) {
    .algorithm {
        position: static;
        font-size: 22px;
        width: auto;
        height: auto;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: center;
    }
    .algorithm ul {
    }
    .algorithm ul li {
        margin: 1em;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .algorithm .algorithm__bg {
        display: none;
    }
    .algorithm .algorithm__icon {
        position: static;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .algorithm .algorithm__step {
        position: static;
        width: auto;
        height: auto;
        margin: 0 0 0 1em;
    }
    .algorithm .algorithm__name {
        font-size: 1.2em;
        position: static;
        width: auto;
    }
    .algorithm__name span {
        color: var(--color-step-1);
        font-size: 0.55em;
    }
    .algorithm .algorithm__desc {
        margin: auto 0 0;
        font-size: 1em;
        text-align: left;
    }
}
@media screen and (max-width: 480px) {
    .algorithm .algorithm__desc {
        font-size: 0.6em;
    }
    .algorithm {
        margin: 0 auto -30px;
    }
}