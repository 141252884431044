/* Contact Section */
#contact {
    padding: 100px 0 60px;
    background: rgb(40, 32, 71);
    background: linear-gradient(165deg, rgba(40, 32, 71, 1) 0%, rgba(31, 30, 96, 1) 10%, rgba(2, 26, 70, 1) 35%, rgba(3, 50, 106, 1) 65%, rgba(1, 105, 194, 1) 100%);
    overflow: hidden;
}

#contact .section-title {
    margin-bottom: 40px;
}

#contact p {
    color: #ffffff;
}

#contact .section-title p {
    font-size: 16px;
}

#contact h2 {
    position: relative;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

#contact .section-title h2::after {
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, 0.3);
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
}

#contact h3 {
    color: #fff;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 400;
}
#contact .contact__items {
    margin: 0 0 0 0;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
}
#contact .contact__item {
    margin: 10px 40px 0 0;
}
#contact .contact__item img {
    width: 32px;
    margin: -6px 0 0 0;
}

#contact .contact__item span {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    font-size: 16px;
}

#contact .contact__item i.fa {
    margin-right: 10px;
}
#contact .contact__item .contact__link a span {
    border-bottom: 1px solid #ffffff;
    text-transform: capitalize;
}
#contact .contact__item.contact__link a span:hover {
    border-bottom: none;
}

#contact .social {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.email-btn {
    padding: 5px 10px;
    border: 1px solid #ffffff;
    border-radius: 16px;
    cursor: pointer;
}
.in-btn {
    padding: 5px 10px;
    border: 1px solid #ffffff;
    border-radius: 16px;
    cursor: pointer;
}
@media screen and (max-width: 320px) {
    #contact .contact__items {
        justify-content: start;
    }
    #contact .contact__item:nth-child(2) {
        margin: 10px 0 0 10px;
    }
}