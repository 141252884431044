/* Header Section */
#header {
    position: relative;
    background: rgb(40, 32, 71);
    background: linear-gradient(165deg, rgba(40, 32, 71, 1) 0%, rgba(31, 30, 96, 1) 10%, rgba(2, 26, 70, 1) 35%, rgba(3, 50, 106, 1) 65%, rgba(1, 105, 194, 1) 100%);
    overflow: hidden;
    height: 100vh;
}
.header {
    display: flex;
    justify-content: center;
    align-items: center;
}
#header .modal-video-button {
    margin: 100px 0;
}
#header .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    /*background-color: transparent;*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center center;*/
    overflow: hidden;
    z-index: 2;
    background: rgb(129,62,219);
    background: linear-gradient(0deg, rgba(129,62,219,1) 0%, rgba(54,114,228,1) 100%);
}
#header .bg .bg__image {
    object-fit: cover;
}
#header .bg:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    background-color: #282049;
    opacity: 0.30;
    z-index: 3;
}
/*#header .bg__gradient {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    right: 50%;*/
/*    bottom: 0;*/
/*    background: transparent;*/
/*    background: linear-gradient(90deg, rgba(46, 43, 117, 1) 0%, rgba(46, 43, 117, 1) 50%, rgba(46, 43, 117, 0) 100%);*/
/*    z-index: 4;*/
/*}*/
#header .container {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: stretch;
    flex-wrap: wrap;
    height: 1024px;
}
#header .header__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 560px;
}
#header .header__column.header__column--left {
    flex-shrink: 1;
}
#header .header__column.header__column--right {
    flex-shrink: 2;
}
#header .header__content {
    position: relative;
}
#header .header__column.header__column--left .header__content {
    margin-left: 27px;
}
#header .content__text {
    position: relative;
    z-index: 2;
}
#header .content__bg {
    position: absolute;
    left: -27px;
    top: -24px;
    right: 10%;
    bottom: -26px;
    border-radius: 5px;
    z-index: 1;
    background: linear-gradient(90deg, rgba(46, 43, 117, 1) 0%, rgba(25, 26, 91, 0.5) 40%, rgba(46, 43, 117, 0) 100%);
}
#header .header__logo {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(40%, -50%) scale(1);
    opacity: 0.1;
    animation: pulse 2s ease-in-out 1;
    z-index: 3;
    width: 90%;
    height: auto;
}
@keyframes pulse {
    from {
        transform: translate(200%, -50%) scale(5);
    }
    to {
        transform: translate(40%, -50%) scale(1);
    }
}
#header .header__intro h1 {
    text-align: center;
    font-family: "Lato", sans-serif;
    color: #fff;
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 60px;
    text-shadow: #282049 0 0 10px;
}
#header .header__intro .div {
    margin: 0 auto;
}
#header .header__intro h1 span {
    margin-left: 15px;
    vertical-align: sub;
    line-height: 53px;
}
#header .header__intro h2 {
    font-family: "Lato", sans-serif;
    color: #fff;
    font-size: 54px;
    font-weight: 700;
    margin: 0;
    text-shadow: #282049 0 0 10px;
    text-transform: unset;
}
#header .header__intro h2 span {
    vertical-align: -webkit-baseline-middle;
}
#header .header__intro p {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    margin: 20px 0;
    text-shadow: #282049 0 0 10px;
}
#header .header__intro .header__desc {
    position: relative;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    margin: 30px 0 35px;
    text-shadow: #282049 0 0 10px;
    list-style: none;
    padding: 0;
}
#header .header__link {
    height: 40px;
}
#header .header__link a {
    color: #C8BFE7;
    font-weight: 700;
    font-size: 24px;
    transition: all .5s ease-in-out;
    line-height: 1;
}
#header .header__link a:hover {
    font-size: 25px;
    text-decoration: none;
}
@media screen and (max-width: 1400px) {
    #header {
        height: 800px;
    }
    #header .container {
        height: 100%;
    }
    #header .bg {
        bottom: 0;
        transform: translateY(0);
    }
    #header .modal-video-button {
        position: static;
        margin: 40px 0 0 0;
    }
}
@media screen and (max-width: 1200px) {
    #header .container {
        justify-content: center;
    }
    #header .header__intro .header__desc {
        margin: 40px 0 40px;
    }
}
@media screen and (max-width: 1024px) {
    #header .container {
        height: 576px;
    }
    #header .header__column.header__column--left .header__content {
        margin-left: 0;
    }
    #header .header__intro p {
        margin: 10px 0;
    }
    #header .header__intro ul {
        margin: 20px 0 30px;
    }
    #header .header__intro li {
        font-size: 18px;
    }
    #header .header__intro li i {
        font-size: 18px;
    }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
    #header .container {
        height: 570px;
    }
    #header .header__intro h2 {
        font-size: 50px;
    }
    #header .header__intro .header__desc {
        font-size: 18px;
        margin: 20px 0;
    }
    #header .header__intro li {
        font-size: 16px;
    }
    #header .header__intro li i {
        font-size: 16px;
    }
}
@media screen and (max-width: 320px) {
}