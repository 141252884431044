/* Navigation */
#menu {
    padding: 10px 5px;
    z-index: 4;
    margin: 0;
}
#menu .container {
    position: relative;
}
#menu.navbar-sticky-top {
    position: sticky;
    z-index: 10;
    top: 0;
}
#menu.navbar-default {
    background-color: #ffffff;
    border-color: rgba(231, 231, 231, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
#menu.navbar-default {
    overflow: hidden;
}
#menu a.navbar-brand {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary-color);
    text-transform: uppercase;
    padding: 0;
    height: 71px;
    width: 129px;
}
#menu.navbar-default .navbar-nav > li > a {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    padding: 8px 2px;
    border-radius: 0;
    margin: 17px 7px;
    font-weight: 500;
    font-size: 15px;
    color: #555555;
    letter-spacing: 1px;
}
#menu.navbar-default .navbar-nav > li > a:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, var(--primary-dark-color) 0%, var(--primary-color) 100%);
    content: "";
    transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
    width: 100%;
}
#menu.navbar-default .navbar-collapse {
    border: none;
}
#menu.navbar-default .navbar-nav > .active > a,
#menu.navbar-default .navbar-nav > .active > a:hover,
#menu.navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent;
}
#menu.navbar-default .navbar-nav > .active > a:after,
#menu.navbar-default .navbar-nav > .active > a:hover:after,
#menu.navbar-default .navbar-nav > .active > a:focus:after {
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    bottom: -1px !important;
    width: 100% !important;
    height: 2px !important;
    background: linear-gradient(to right, var(--primary-dark-color) 0%, var(--primary-color) 100%) !important;
    content: "" !important;
    transition: width 0.2s !important;
}
#menu.navbar-default .navbar-toggle {
    border-radius: 0;
    margin: 14px 0 0 0;
    border-color: transparent;
    float: none;
}
#menu.navbar-default .navbar-toggle:hover,
#menu.navbar-default .navbar-toggle:focus {
    background-color: #fff;
    border-color: transparent;
}
#menu.navbar-default .navbar-toggle:hover > .icon-bar {
    background-color: var(--primary-color);
}
#menu.navbar-default .navbar-toggle .icon-bar {
    display: block;
    width: 30px;
    height: 4px;
    border-radius: 2px;
    transition: all .5s ease-in-out;
    position: relative;
}
#menu .navbar__button {
    text-align: end;
    position: absolute;
    right: 0;
}
#menu .navbar__logo {
    position: absolute;
    left: 0;
}
@media screen and (max-width: 768px) {
    #menu .navbar__logo {
        position: relative;
    }
    #menu a.navbar-brand {
        height: 55px;
        width: 100px;
        margin: 8px auto;
    }
}
@media screen and (max-width: 480px) {
    #menu a.navbar-brand {
        margin: 8px 0 8px -50px;
    }
}