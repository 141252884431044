#formsubmit form {
    padding-top: 20px;
}

#formsubmit .text-danger {
    color: #cc0033;
    text-align: left;
}

#formsubmit .btn-custom {
    margin: 30px 0;
    background: transparent;
    border: 2px solid #fff;
}

#formsubmit .btn-custom:hover {
    color: var(--primary-dark-color);
    background: #fff;
}

label {
    font-size: 12px;
    font-weight: 400;
    float: left;
}

#formsubmit .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

#formsubmit .form-control:focus {
    border-color: #999;
    outline: 0;
    -webkit-box-shadow: transparent;
    box-shadow: transparent;
}

#formsubmit .form-control::-webkit-input-placeholder {
    color: #777;
}

#formsubmit .form-control:-moz-placeholder {
    color: #777;
}

#formsubmit .form-control::-moz-placeholder {
    color: #777;
}

#formsubmit .form-control:-ms-input-placeholder {
    color: #777;
}