.button.button--schedule {
    font-family: "Raleway", sans-serif;
    align-items: center;
    background-color: transparent;
    border-radius: 100vmax;
    display: inline-flex;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 0;
    width: 170px;
    height: 50px;
    position: relative;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    transition: box-shadow .5s ease-in-out;
    margin: 10px 0;
    outline: none
}
.button.button--schedule span {
    line-height: 1;
}
.button.button--schedule:hover {
    box-shadow: 2px 2px 10px rgba(62, 57, 161, .5);
}
@media screen and (max-width: 768px) {
    .button.button--schedule {
        font-size: 13px;
        width: 150px;
        height: 40px;
        margin: 15px 0 0 -60px;
    }
}
