/* Footer Section*/
#footer {
    background: #f6f6f6;
    padding: 30px 0;
}
#footer p {
    color: #888;
    font-size: 14px;
    margin: 0 auto;
    text-align: center;
}
@media screen and (max-width: 480px) {
    #footer p {
        width: 260px;
    }
}